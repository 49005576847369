import { Component, OnInit } from '@angular/core';

import { CertificadoService } from '@certificados/services/certificado.service';
import { EmpleadoService } from '@empleados/services/empleado.service';

import { Usuario } from '@seguridad/models/usuario.model';

@Component({
    selector: 'app-certificados-formulario',
    templateUrl: './certificados-formulario.component.html',
    providers: [ 
        CertificadoService,
        EmpleadoService
    ]
})
export class CertificadosFormularioComponent implements OnInit {
    public pdfUrl: any;
    public usuario: Usuario;
    public cargando: boolean = false;

    constructor(
        private certificadoService: CertificadoService,
        private empleadoService: EmpleadoService
    ) {
        this.usuario = {} as Usuario;
    }

    public ngOnInit(): void {
        this.consultarEmpleado();
    }

    public imprimirCertificadoLaboral(): void {
        this.cargando = true;

        this.certificadoService.imprimirCertificadoLaboral().subscribe(res => {
            let blob: Blob = res.body as Blob;
            let url = window.URL.createObjectURL(blob);
      
            let a = document.createElement('a');
            a.download = `CERTIFICADO LABORAL ${this.usuario.nombre.toUpperCase()}.pdf`;
            a.href = url;
            a.click();
      
          });
    }

    private consultarEmpleado(): void {
        this.empleadoService.consultar().subscribe(
            respuesta => this.usuario = respuesta.datos
        );
    }
}
