
<div id="layout-wrapper">
    <app-encabezado></app-encabezado>
    <app-menu></app-menu> 
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Desprendibles de Nómina</h4>

                            <div class="page-title-right">
                                <ol class="breadcrumb m-0">
                                    <li class="breadcrumb-item"><a href="javascript: void(0);">Inicio</a></li>
                                    <li class="breadcrumb-item active">Desprendibles de Nómina</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1"><i class="fa fa-print"></i> Desprendibles de Nómina</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <p-table [value]="liquidaciones" 
                                    styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines" 
                                    [tableStyle]="{ 'min-width': '90rem' }"
                                    [paginator]="true"
                                    [showCurrentPageReport]="true"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                    [scrollable]="true"
                                    [rows]="10"
                                    dataKey="idLiquidacionNomina"
                                    [rowHover]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="width: 5rem; min-width: 5rem" pFrozenColumn>&nbsp;</th>
                                            <th style="min-width: 8rem" pSortableColumn="consecutivo" pFrozenColumn>Consecutivo</th>
                                            <th pSortableColumn="nombreTipoNomina">Nómina</th>
                                            <th pSortableColumn="periodo">Periodo</th>
                                            <th pSortableColumn="fecha">Fecha</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <p-columnFilter type="numeric" field="consecutivo" [showMenu]="false" [showClearButton]="false" [showButtons]="false" [useGrouping]="false"></p-columnFilter>
                                            </th>
                                            <th>
                                                <p-columnFilter type="text" field="nombreTipoNomina" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                                            </th>
                                            <th>
                                                <p-columnFilter type="text" field="periodo" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                                            </th>
                                            <th>
                                                <p-columnFilter type="text" field="fecha" matchMode="equals" [showMenu]="false" [showClearButton]="false"></p-columnFilter>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-liquidacion>
                                        <tr >
                                            <td style="text-align:center" pFrozenColumn>
                                                <a href="javascript:void(0)" (click)="imprimir(liquidacion)"><i class="fa fa-print"></i></a>
                                            </td>
                                            <td>{{liquidacion.consecutivo}}</td>
                                            <td>{{liquidacion.nombreTipoNomina}}</td>
                                            <td>{{liquidacion.periodo}}</td>
                                            <td>{{liquidacion.fecha}}</td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="5">No se encontraron datos.</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalFormato let-c="close" let-d="dismiss">
    <div class="modal-header encabezado-modal">
        <span class="modal-title">&nbsp;</span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cerrar')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <iframe [src]="pdfUrl | safe" width="1105" height="500" style="border: 1px;" type="application/pdf"></iframe>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="c('Cerrar')">Cerrar</button>
    </div>
</ng-template>