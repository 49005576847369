import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AlmacenamientoService } from '@shared/services/almacenamiento.service';

@Injectable()
export class AutenticacionGuard  {
    constructor(
        private router: Router,
        private almacenamientoService: AlmacenamientoService
    ) {}

    public canActivate(): boolean {
        if (this.almacenamientoService.existeTokenAutenticado()) {
            return true;
        }

        this.router.navigate(['/login']);

        return false;
    }

    public canActivateChild(): boolean {
        return this.canActivate();
    }
}
