<div class="app-menu navbar-menu">
    <div class="navbar-brand-box">
        <a href="index.html" class="logo logo-light">
            <span class="logo-sm">454545
                <img src="assets/images/logo_confianza_blanco.png" alt="" height="55">
            </span>
            <span class="logo-lg">45457
                <img src="assets/images/logo_confianza_blanco.png" alt="" height="55">
            </span>
        </a>
        <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i class="ri-record-circle-line"></i>
        </button>
    </div>

    <div id="scrollbar" data-simplebar="init" class="h-100 simplebar" >
        <div class="container-fluid">
            
            <ul class="navbar-nav" id="navbar-nav">
                <li class="nav-item">
                    <a routerLink="/liquidacion" class="nav-link" data-key="t-analytics">
                        <i class="fa fa-print"></i> <span data-key="liquidacion">Desprendibles de nómina</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a routerLink="/certificados" class="nav-link" data-key="t-analytics">
                        <i class="fa fa-print"></i> <span data-key="certificados">Certificado laboral</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>