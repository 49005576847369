import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AlmacenamientoService } from '@shared/services/almacenamiento.service';

import { Parametro } from '@administracion/models/parametro.model';
import { Usuario } from '@seguridad/models/usuario.model';
import { ParametroService } from '@administracion/services/parametro.service';
import { EmpleadoService } from '@empleados/services/empleado.service';

@Component({
    selector: 'app-encabezado',
    templateUrl: './encabezado.component.html',
    providers: [ ParametroService, EmpleadoService ]
})
export class EncabezadoComponent {
    @Input() parametro: Parametro;
    @Input() usuario: Usuario;

    constructor(
        private router: Router,
        private almacenamientoService: AlmacenamientoService,
        private parametroService: ParametroService,
        private empleadoService: EmpleadoService
    ) {
        this.parametro = {} as Parametro;
        this.usuario = {} as Usuario;

        this.consultarParametros();
        this.consultarEmpleado();
    }

    public cambiarClave(): void {
        this.router.navigate(['/clave']);
    }

    public salir(): void {
        this.almacenamientoService.limpiar();
  
        this.router.navigate(['/login']);
    }

    private consultarParametros(): void {
        this.parametroService.consultar().subscribe(
            respuesta => this.parametro = respuesta.datos
        );
    }

    private consultarEmpleado(): void {
        this.empleadoService.consultar().subscribe(
            respuesta => this.usuario = respuesta.datos
        );
    }
}
