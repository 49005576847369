<div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card overflow-hidden card-login">
                        <form [formGroup]="formulario" (ngSubmit)="validar()" >
                            <div class="row g-0">
                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div class="mb-4">
                                                <a href="index.html" class="d-block">
                                                    <img src="assets/images/logo_entidad.png" alt="" height="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                <div class="col-lg-6">
                                    <div class="p-lg-5 p-4">
                                        <div class="mb-4">
                                            <a href="index.html" class="d-block">
                                                <img src="assets/images/logo_confianza_azul.png" alt="" height="60">
                                            </a>
                                        </div>
                                        <div>
                                            <h5 class="text-primary">Bienvenido al Portal de Empleados</h5>
                                            <p class="text-muted">Ingrese sus datos.</p>
                                        </div>
                                        <p-messages></p-messages>
                                        <div class="mt-4">
                                            <div class="mb-3">
                                                <label for="identificacion" class="form-label">Número de documento</label>
                                                <input #identificacion
                                                    formControlName="identificacion"
                                                    class="form-control" 
                                                    [class.ng-invalid]="campoIdentificacionEsInvalido"
                                                    [class.ng-dirty]="campoIdentificacionEsInvalido"
                                                    type="text" 
                                                    pInputText 
                                                    name="identificacion" 
                                                    id="identificacion" 
                                                    maxlength="20"
                                                    autofocus>
                                                <small id="identificacion-help" class="p-error block" *ngIf="campoIdentificacionEsInvalido">Número de documento es obligatorio.</small>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label" for="clave">Contraseña</label>
                                                <p-password #clave 
                                                    formControlName="clave"
                                                    name="clave" 
                                                    id="clave" 
                                                    [class.ng-invalid]="campoClaveEsInvalido"
                                                    [class.ng-dirty]="campoClaveEsInvalido"
                                                    [toggleMask]="true" 
                                                    [feedback]="false">
                                                </p-password>
                                            </div>
                                            <div class="mt-4">
                                                <button type="submit" class="btn btn-success w-100">Ingresar</button>
                                            </div>
                                            <div class="mt-4 text-center">
                                                <a routerLink="/recuperacion-clave" class="text-muted">¿Olvidó su contraseña?</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>    
    </div>


</div>

<p-blockUI [blocked]="procesando">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</p-blockUI>