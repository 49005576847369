import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router"

import { MessageService } from 'primeng/api';

import { AlmacenamientoService } from '@shared/services/almacenamiento.service';
import { ParametroService } from '@administracion/services/parametro.service';

import { Parametro } from '@administracion/models/parametro.model';
import { EmpleadoService } from '@empleados/services/empleado.service';
import { EmpleadoCambioClave } from '@empleados/models/empleado-cambio-clave.model';
import { EmpleadoAutenticacion } from '@empleados/models/empleado-autenticacion.model';

@Component({
    selector: 'app-seguridad-clave',
    templateUrl: './clave.component.html',
    providers: [
        ParametroService,
        EmpleadoService
    ]
})
export class ClaveComponent implements AfterContentChecked, OnInit, AfterViewInit {
    @ViewChild('claveActual', { static: false }) campoClave: ElementRef;
    public formulario: UntypedFormGroup;
    public empleadoAutenticacion: EmpleadoAutenticacion;
    public parametro: Parametro;
    public procesando: boolean = false;
    public empleadoCambioClave: EmpleadoCambioClave;

    get campoClaveEsInvalido() {
        return this.formulario.get('claveActual').touched 
            && this.formulario.get('claveActual').invalid
            && this.formulario.get('claveNueva').touched 
            && this.formulario.get('claveNueva').invalid
            && this.formulario.get('claveNuevaRepetir').touched 
            && this.formulario.get('claveNuevaRepetir').invalid;
    }

    constructor(
        private cd: ChangeDetectorRef,
        private mensajeService: MessageService,
        private almacenamientoService: AlmacenamientoService,
        private parametroService: ParametroService,
        private empleadoService: EmpleadoService
    ) {
        this.parametro = {} as Parametro;
        this.parametro.portalTransaccionalActivo = true;
        this.parametro.pagosLineaActivo = true;
        this.parametro.declaracionIcaActivo = true;

        this.empleadoCambioClave = {} as EmpleadoCambioClave;
    }

    public ngOnInit(): void {
        this.formulario = new UntypedFormGroup ({
            claveActual: new UntypedFormControl ('', Validators.required),
            claveNueva: new UntypedFormControl ('', Validators.required),
            claveNuevaRepetir: new UntypedFormControl ('', Validators.required)
        });

        this.consultarParametros();
        this.consultarEmpleado();
    }

    public ngAfterViewInit(): void {
        this.campoClave.nativeElement.focus();
        this.cd.detectChanges();
    }

    public ngAfterContentChecked(): void {
        this.cd.detectChanges();
    }

    public cambiarClave(): void {
        this.mensajeService.clear();

        if (this.empleadoCambioClave.claveNueva != this.empleadoCambioClave.claveNuevaRepetir) {
            this.mensajeService.add({ severity: 'error', detail: 'La nueva contraseña no coincide.', closable: false });
            return;
        }

        this.procesando = true;

        this.empleadoService.cambiarClave(this.empleadoAutenticacion.idEmpleado, this.empleadoCambioClave).subscribe(
            respuesta => {
                this.procesando = false;

                if (!respuesta.exitoso) {
                    this.mensajeService.add({ severity: 'error', detail: respuesta.mensaje });
                    return;
                }

                this.mensajeService.add({ severity: 'success', detail: 'Se cambia exitosamente la contraseña' });

                this.formulario.reset();
            },
            error => {
                this.procesando = false;

                this.mensajeService.add({ severity: 'error', detail: error.error.mensaje });
            }
        );
    }

    private consultarParametros(): void {
        this.parametroService.consultar().subscribe(
            respuesta => {
                this.parametro = respuesta.datos;
            },
            error => {
                this.mensajeService.add({ severity: 'error', detail: error.error.mensaje });
            });
    }

    private consultarEmpleado(): void {
        this.empleadoService.consultar().subscribe(
            respuesta => {
                this.empleadoAutenticacion = respuesta.datos;

                this.almacenamientoService.guardarTokenAutenticado(this.empleadoAutenticacion.token);
            },
            error => {
                this.mensajeService.add({ severity: 'error', detail: error.error.mensaje });
            });
    }

    
}
