import { Component, Input } from '@angular/core';

import { Parametro } from '@administracion/models/parametro.model';

@Component({
    selector: 'app-soporte',
    templateUrl: './soporte.component.html',
    providers: [ ]
})
export class SoporteComponent {
    @Input() parametro: Parametro;

    constructor() {
        this.parametro = {} as Parametro;
        this.parametro.mensajeSoportePortalTransaccional = '';
    }
}
