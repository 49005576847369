import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { DropdownModule}  from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { BlockUIModule } from 'primeng/blockui';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { AppComponent } from './app.component';

import { AlmacenamientoService } from './shared/services/almacenamiento.service';
import { AutenticacionGuard } from '@shared/guards/autenticacion.guard';
import { InterceptorService } from './shared/services/interceptor.service';

import { AppRoutingModule } from './app.routing';

import { EncabezadoComponent } from '@shared/components/encabezado/encabezado.component';
import { ClaveComponent } from '@seguridad/components/clave/clave.component';
import { RecuperacionClaveComponent } from '@seguridad/components/recuperacion-clave/recuperacion-clave.component';
import { LoginComponent } from '@seguridad/components/login/login.component';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { PieComponent } from '@shared/components/pie/pie.component';
import { LiquidacionNominaListaComponent } from '@liquidaciones/components/liquidacion-nomina-lista/liquidacion-nomina-lista.component';
import { CertificadosFormularioComponent } from '@certificados/components/certificados-formulario/certificados-formulario.component';
import { SoporteComponent } from '@shared/components/soporte/soporte.component';

@NgModule({
  declarations: [
    AppComponent,
    EncabezadoComponent,
    MenuComponent,
    PieComponent,
    ClaveComponent,
    RecuperacionClaveComponent,
    CertificadosFormularioComponent,
    LiquidacionNominaListaComponent,
    LoginComponent,
    SoporteComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MessageModule,
    MessagesModule,
    TableModule,
    CalendarModule,
    ConfirmDialogModule,
    CheckboxModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    InputNumberModule,
    PasswordModule,
    ProgressBarModule,
    RadioButtonModule,
    BlockUIModule,
    AutoCompleteModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AlmacenamientoService,
    AutenticacionGuard,
    MessageService,
    ConfirmationService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
