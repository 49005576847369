<div id="layout-wrapper">
    <app-encabezado></app-encabezado>
    <app-menu></app-menu>
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Cambiar contraseña</h4>

                            <div class="page-title-right">
                                <ol class="breadcrumb m-0">
                                    <li class="breadcrumb-item"><a href="javascript: void(0);">Inicio</a></li>
                                    <li class="breadcrumb-item active">Cambiar contraseña</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1"><i class="fa fa-user"></i> Cambiar contraseña</h5>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="formulario">
                            <div class="row">
                                <div class="col-sm-12">
                                    <p-messages></p-messages>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="claveActual" class="form-label">Contraseña actual*</label>
                                        <input #claveActual
                                            formControlName="claveActual" 
                                            class="form-control" 
                                            [class.ng-invalid]="campoClaveEsInvalido"
                                            [class.ng-dirty]="campoClaveEsInvalido"
                                            [(ngModel)]="empleadoCambioClave.claveActual" 
                                            type="password" 
                                            name="claveActual" 
                                            id="claveActual" 
                                            pInputText
                                            maxlength="20">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="claveNueva" class="form-label">Contraseña nueva*</label>
                                        <input #claveNueva
                                            formControlName="claveNueva" 
                                            class="form-control" 
                                            [class.ng-invalid]="campoClaveEsInvalido"
                                            [class.ng-dirty]="campoClaveEsInvalido"
                                            [(ngModel)]="empleadoCambioClave.claveNueva" 
                                            type="password" 
                                            name="claveNueva" 
                                            id="claveNueva" 
                                            pInputText
                                            maxlength="20">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="claveNuevaRepetir" class="form-label">Repetir contraseña nueva*</label>
                                        <input #claveNuevaRepetir
                                            formControlName="claveNuevaRepetir" 
                                            class="form-control" 
                                            [class.ng-invalid]="campoClaveEsInvalido"
                                            [class.ng-dirty]="campoClaveEsInvalido"
                                            [(ngModel)]="empleadoCambioClave.claveNuevaRepetir" 
                                            type="password" 
                                            name="claveNuevaRepetir" 
                                            id="claveNuevaRepetir" 
                                            pInputText
                                            maxlength="20">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <button type="button" class="btn btn-info" [disabled]="formulario.invalid" (click)="cambiarClave()"><i class="fa fa-save"></i> Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <app-pie [parametro]="parametro"></app-pie>
    </div>
</div>

<p-blockUI [blocked]="procesando">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</p-blockUI>