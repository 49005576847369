import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageService } from 'primeng/api';

import { LiquidacionNominaService } from '@liquidaciones/services/liquidacion-nomina.service';

import { LiquidacionNomina } from '@liquidaciones/models/liquidacion-nomina.model';

@Component({
    selector: 'app-liquidaciones-liquidacion-nomina-lista',
    templateUrl: './liquidacion-nomina-lista.component.html',
    providers: [ LiquidacionNominaService ]
})
export class LiquidacionNominaListaComponent implements OnInit {
    public liquidaciones: LiquidacionNomina[];
    public pdfUrl: any;
    public cargando: boolean = false;

    constructor(
        protected _cd: ChangeDetectorRef,
        private mensajeService: MessageService,
        protected _liquidacionNominaService: LiquidacionNominaService
    ) {
        this.liquidaciones = [];
    }

    public ngOnInit(): void {
        this.consultar();
    }

    public imprimir(liquidacion: LiquidacionNomina): void {
        if (!liquidacion) return;

        this.cargando = true;

        this._liquidacionNominaService.imprimir(liquidacion.idLiquidacionNomina).subscribe(res => {
            let blob: Blob = res.body as Blob;
            let url = window.URL.createObjectURL(blob);
      
            let a = document.createElement('a');
            a.download = `DESPRENDIBLE ${liquidacion.nombreTipoNomina} ${liquidacion.periodo}.pdf`;
            a.href = url;
            a.click();
      
          });
    }
    
    private consultar(): void {
        this.cargando = true;
        this._cd.detectChanges();

        this._liquidacionNominaService.consultarTodos().subscribe({
            next: respuesta => {
                this.liquidaciones = respuesta.datos;

                this.cargando = false;
            },
            error: error => {
                console.log(error);

                this.cargando = false;

                this.mensajeService.add({ severity: 'error', detail: error.error.mensaje });
            }
        }); 
    }
}
