import { Component, Input } from '@angular/core';

import { AlmacenamientoService } from '@shared/services/almacenamiento.service';

import { EstadosUsuario } from '@seguridad/enumeraciones/estados-usuario.enum';

import { Parametro } from '@administracion/models/parametro.model';
import { Usuario } from '@seguridad/models/usuario.model';
import { ParametroService } from '@administracion/services/parametro.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    providers: [ ParametroService ]
})
export class MenuComponent {
    @Input() parametro: Parametro;
    @Input() usuario: Usuario;
    public autenticado: boolean;
    public idEstadoActivo: number = EstadosUsuario.activo;

    constructor(
        private almacenamientoService: AlmacenamientoService,
        private parametroService: ParametroService
    ) {
        this.parametro = {} as Parametro;
        this.usuario = {} as Usuario;
        this.usuario.idEstado = EstadosUsuario.noRegistrado;

        this.autenticado = this.almacenamientoService.existeTokenAutenticado();

        this.consultarParametro();
    }

    private consultarParametro(): void {
        this.parametroService.consultar().subscribe(
            respuesta => this.parametro = respuesta.datos
        );
    }
}
