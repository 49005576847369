import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AlmacenamientoService } from './almacenamiento.service';

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
    constructor(
        private almacenamientoService: AlmacenamientoService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.almacenamientoService.existeToken()) return next.handle(req);

        const token = this.almacenamientoService.obtenerToken();

        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });

        const reqClone = req.clone({ headers });

        return next.handle(reqClone);
    }
}
