import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RespuestaDatos } from '@shared/models/respuesta-datos.model';

@Injectable()
export class CuentaTecnicaService {
    private urlBase: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') urlBase: string) 
    { 
        this.urlBase = `${urlBase}CuentaTecnica`;
    }

    public autenticar(): Observable<RespuestaDatos<string>> {
        return this.http.post(this.urlBase, null);
    }
}
