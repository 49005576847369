
<div id="layout-wrapper">
    <app-encabezado></app-encabezado>
    <app-menu></app-menu> 
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 class="mb-sm-0">Certificado laboral</h4>

                            <div class="page-title-right">
                                <ol class="breadcrumb m-0">
                                    <li class="breadcrumb-item"><a href="javascript: void(0);">Inicio</a></li>
                                    <li class="breadcrumb-item active">Certificado laboral</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1"><i class="fa fa-print"></i> Certificado laboral</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <button type="button" class="btn btn-info" (click)="imprimirCertificadoLaboral()"><i class="fa fa-print"></i> Descargar Certificado laboral</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalFormato let-c="close" let-d="dismiss">
    <div class="modal-header encabezado-modal">
        <span class="modal-title">&nbsp;</span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cerrar')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <iframe [src]="pdfUrl | safe" width="1105" height="500" style="border: 1px;" type="application/pdf"></iframe>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="c('Cerrar')">Cerrar</button>
    </div>
</ng-template>