import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AutenticacionGuard } from '@shared/guards/autenticacion.guard';

import { ClaveComponent } from '@seguridad/components/clave/clave.component';
import { RecuperacionClaveComponent } from '@seguridad/components/recuperacion-clave/recuperacion-clave.component';
import { LoginComponent } from '@seguridad/components/login/login.component';
import { LiquidacionNominaListaComponent } from '@liquidaciones/components/liquidacion-nomina-lista/liquidacion-nomina-lista.component';
import { CertificadosFormularioComponent } from '@certificados/components/certificados-formulario/certificados-formulario.component';

const rutas: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'recuperacion-clave', component: RecuperacionClaveComponent },
    { 
        path: 'clave', 
        component: ClaveComponent,
        canActivate: [ AutenticacionGuard ],
        runGuardsAndResolvers: 'always',
    },
    { 
        path: 'liquidacion', 
        component: LiquidacionNominaListaComponent,
        canActivate: [ AutenticacionGuard ],
        runGuardsAndResolvers: 'always',
    },
    { 
        path: 'certificados', 
        component: CertificadosFormularioComponent,
        canActivate: [ AutenticacionGuard ],
        runGuardsAndResolvers: 'always',
    },
    { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [ RouterModule.forRoot(rutas, {}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
