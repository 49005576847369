import { Injectable } from '@angular/core';

@Injectable()
export class AlmacenamientoService {
    private claveTokenAutenticado: string = 'auth';
    private claveTokenVisitante: string = 'visitante';

    constructor() 
    { }

    public guardarTokenAutenticado(token: string): void {
        localStorage.setItem(this.claveTokenAutenticado, token);
        localStorage.removeItem(this.claveTokenVisitante);
    }

    public guardarTokenVisitante(token: string): void {
        localStorage.setItem(this.claveTokenVisitante, token);
        localStorage.removeItem(this.claveTokenAutenticado);
    }

    public obtenerToken(): string {
        let token = localStorage.getItem(this.claveTokenAutenticado);

        if (!token) token = localStorage.getItem(this.claveTokenVisitante);

        return token;
    }

    public existeToken(): boolean {
        let token = this.obtenerToken();

        return (!token || token == undefined || token == '') ? false : true;
    }

    public existeTokenAutenticado(): boolean {
        let token = localStorage.getItem(this.claveTokenAutenticado);

        return (!token || token == undefined || token == '') ? false : true;
    }

    public limpiar(): void {
        localStorage.clear();
    }
}
