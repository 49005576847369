import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CertificadoService {
    private urlBase: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') urlBase: string) { 
        this.urlBase = `${urlBase}Certificado`;
    }

    public imprimirCertificadoLaboral() {
        return this.http.get(`${this.urlBase}/CertificadoLaboral`, { observe: 'response', responseType: 'blob' });
    }
}
