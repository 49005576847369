import { Component, Input } from '@angular/core';

import { Parametro } from '@administracion/models/parametro.model';

@Component({
    selector: 'app-pie',
    templateUrl: './pie.component.html',
    providers: [ ]
})
export class PieComponent {
    @Input() parametro: Parametro;

    constructor() {
        this.parametro = {} as Parametro;
    }
}
