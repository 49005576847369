import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RespuestaDatos } from '@shared/models/respuesta-datos.model';
import { RespuestaBasica } from '@shared/models/respuesta-basica.model';
import { Usuario } from '@seguridad/models/usuario.model';
import { UsuarioValidacion } from '@seguridad/models/usuario-validacion.model';
import { EmpleadoAutenticacion } from '../models/empleado-autenticacion.model';
import { EmpleadoCambioClave } from '@empleados/models/empleado-cambio-clave.model';

@Injectable()
export class EmpleadoService {
    private urlBase: string ;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') urlBase: string) 
    { 
        this.urlBase = `${urlBase}Empleado`;
    }

    public consultar(): Observable<RespuestaDatos<Usuario>> {
        return this.http.get(this.urlBase);
    }

    public autenticar(empleadoAutenticacion: EmpleadoAutenticacion): Observable<RespuestaDatos<EmpleadoAutenticacion>> {
        return this.http.post(`${this.urlBase}/Autenticacion`, empleadoAutenticacion);
    }

    public cambiarClave(idEmpleado: number, empleadoCambioClave: EmpleadoCambioClave): Observable<RespuestaDatos<EmpleadoCambioClave>> {
        return this.http.put(`${this.urlBase}/Clave/${idEmpleado}`, empleadoCambioClave);
    }

    public restablecerClave(identificacion: string): Observable<RespuestaBasica> {
        return this.http.post(`${this.urlBase}/RestablecerClave/${identificacion}`, null);
    }
}
