import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LiquidacionNomina } from '@liquidaciones/models/liquidacion-nomina.model';
import { RespuestaDatos } from '@shared/models/respuesta-datos.model';

@Injectable()
export class LiquidacionNominaService {
    private urlBase: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') urlBase: string) { 
        this.urlBase = `${urlBase}LiquidacionNomina`;
    }

    public consultarTodos(): Observable<RespuestaDatos<LiquidacionNomina[]>> {
        return this.http.get(this.urlBase);
    }

    public imprimir(idLiquidacionNomina: number) {
        return this.http.get(`${this.urlBase}/Colilla/${idLiquidacionNomina}`, { observe: 'response', responseType: 'blob' });
    }
}
