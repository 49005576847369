import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RespuestaDatos } from '@shared/models/respuesta-datos.model';
import { Parametro } from '@administracion/models/parametro.model';

@Injectable()
export class ParametroService {
    private urlBase: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') urlBase: string) 
    { 
        this.urlBase = `${urlBase}Parametro`;
    }

    public consultar(): Observable<RespuestaDatos<Parametro>> {
        return this.http.get(this.urlBase);
    }
}
