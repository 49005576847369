import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";

import { MessageService } from 'primeng/api';

import { EmpleadoService } from '@empleados/services/empleado.service';

import { EmpleadoAutenticacion } from '@empleados/models/empleado-autenticacion.model';

@Component({
    selector: 'app-seguridad-recuperacion-clave',
    templateUrl: './recuperacion-clave.component.html',
    providers: [
        EmpleadoService
    ]
})
export class RecuperacionClaveComponent implements AfterContentChecked, OnInit {
    public formulario: FormGroup;
    public empleado: EmpleadoAutenticacion;
    public procesando: boolean = false;

    get campoIdentificacionEsInvalido() {
        return this.formulario.get('identificacion').touched 
            && this.formulario.get('identificacion').invalid;
    }

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private mensajeService: MessageService,
        private empleadoService: EmpleadoService
    ) {
        this.empleado = {} as EmpleadoAutenticacion;
    }

    public ngOnInit(): void {
        this.formulario = new FormGroup ({
            identificacion: new FormControl ('', Validators.required)
        });
    }

    public ngAfterContentChecked(): void {
        this.cd.detectChanges();
    }

    public validar(): void {
        if (this.formulario.invalid) return;

        this.mensajeService.clear();

        const empleado: EmpleadoAutenticacion = {
            identificacion: this.formulario.get('identificacion').value
        };

        this.empleadoService.restablecerClave(empleado.identificacion).subscribe(
            respuesta => {
                this.procesando = false;

                if (!respuesta.exitoso) {
                    this.mensajeService.add({ severity: 'error', detail: respuesta.mensaje });
                    return;
                }

                this.mensajeService.add({ severity: 'success', detail: respuesta.mensaje });
            },
            error => {
                this.mensajeService.add({ severity: 'error', detail: error.error.mensaje });

                this.procesando = false;
            });
    }
}
