import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router"

import { MessageService } from 'primeng/api';

import { AlmacenamientoService } from '@shared/services/almacenamiento.service';
import { CuentaTecnicaService } from '@seguridad/services/cuenta-tecnica.service';
import { ParametroService } from '@administracion/services/parametro.service';
import { EmpleadoService } from '@empleados/services/empleado.service';

import { Parametro } from '@administracion/models/parametro.model';
import { EmpleadoAutenticacion } from '@empleados/models/empleado-autenticacion.model';

@Component({
    selector: 'app-seguridad-login',
    templateUrl: './login.component.html',
    providers: [
        CuentaTecnicaService,
        ParametroService,
        EmpleadoService
    ]
})
export class LoginComponent implements AfterContentChecked, OnInit {
    public formulario: FormGroup;
    public empleado: EmpleadoAutenticacion;
    public parametro: Parametro;
    public procesando: boolean = false;

    get campoIdentificacionEsInvalido() {
        return this.formulario.get('identificacion').touched 
            && this.formulario.get('identificacion').invalid;
    }

    get campoClaveEsInvalido() {
        return this.formulario.get('clave').touched 
            && this.formulario.get('clave').invalid;
    }

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private mensajeService: MessageService,
        private almacenamientoService: AlmacenamientoService,
        private cuentaTecnicaService: CuentaTecnicaService,
        private parametroService: ParametroService,
        private empleadoService: EmpleadoService
    ) {
        this.parametro = {} as Parametro;
        this.parametro.portalTransaccionalActivo = true;
        this.parametro.pagosLineaActivo = true;
        this.parametro.declaracionIcaActivo = true;

        this.empleado = {} as EmpleadoAutenticacion;
    }

    public ngOnInit(): void {
        this.formulario = new FormGroup ({
            identificacion: new FormControl ('', Validators.required),
            clave: new FormControl ('', Validators.required)
        });
    }

    public ngAfterContentChecked(): void {
        this.cd.detectChanges();
    }

    public validar(): void {
        if (this.formulario.invalid) return;

        this.mensajeService.clear();

        const empleado: EmpleadoAutenticacion = {
            identificacion: this.formulario.get('identificacion').value,
            clave: this.formulario.get('clave').value
        };

        this.empleadoService.autenticar(empleado).subscribe(
            respuesta => {
                this.procesando = false;

                if (!respuesta.exitoso) {
                    this.mensajeService.add({ severity: 'error', detail: respuesta.mensaje });
                    return;
                }

                this.empleado = respuesta.datos;                   
                this.almacenamientoService.guardarTokenAutenticado(this.empleado.token);

                if (!this.empleado.cambiarClavePortal) {
                    this.router.navigate(['/liquidacion']);
                }
                else{
                    this.router.navigate(['/clave']);
                }
                
            },
            error => {
                this.mensajeService.add({ severity: 'error', detail: error.error.mensaje });

                this.procesando = false;
            });
    }

    private consultarParametros(): void {
        this.parametroService.consultar().subscribe(
            respuesta => {
                this.parametro = respuesta.datos;
            },
            error => {
                this.mensajeService.add({ severity: 'error', detail: error.error.mensaje });
            });
    }
}
